import { template as template_8a95f905306f4e9882c18d80b630371a } from "@ember/template-compiler";
const SidebarSectionMessage = template_8a95f905306f4e9882c18d80b630371a(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
